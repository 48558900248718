import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const HomeAbout = () => {

    return (
        <section data-scrollax-parent="true" id="overview">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" > overview  <span>//</span></div>
                <div className="container">
                <div className="row">
                   
                    <div className="col-md-12">
                        <div className="main-about fl-wrap">
                            <h2> <span>Expediting </span>  Business Outcomes</h2>
                            <p>Indeed, IT is complex and will continue to be so. Many a choices, options, and paths for a given context. Many brands and alternatives for each context with their own strengths and weaknesses.</p>
                         
                            <p>It is beyond an individual/professional/expert to make effective decisions pertaining to a given context. A poor decision can cost you dearly in terms of money, effort, effectiveness, and efficiency.</p>
                            <p>Requires breadth and depth of expertise and experience to articulate objective solution propositions which are brand and technology agnostic.</p>
                            <p>PragICTS brings tier-1 IT expertise and experience so that you can focus on your business. We do the required due diligence to design, implement, manage and support.</p>
                            <p>Talk to us and experience the difference we can bring on board.</p>
                       {/*      <div className="features-box-container fl-wrap">
                                <div className="row">
                                    <div className="features-box col-md-6">
                                        <div className="time-line-icon">
                                            <i className="fal pragicts-software-icon"></i>
                                        </div>
                                        <Link to="/service/software-development"><h3>Software</h3></Link>
                                        <p>Software development and support services. Web (including websites), Mobile and Rich Client Applications.</p>
                                        <p><Link to="/service/software-development">More Details ...</Link></p>
                                    </div>
                                    <div className="features-box col-md-6">
                                        <div className="time-line-icon">
                                            <i className="fal pragicts-infrastructure-icon"></i>
                                        </div>
                                        <a target="_blank" href="https://infrastructure.pragicts.com"><h3>Infrastructure</h3></a>
                                        <p>Implementation, management, and support of ICT Infrastructure.  On-site, remote, hybrid, and on-call service models.</p>
                                        <p><a target="_blank" href="https://infrastructure.pragicts.com">More Details ...</a></p>
                                    </div>
                                    <div className="features-box col-md-6">
                                        <div className="time-line-icon">
                                            <i className="fab pragicts-cloud-icon"></i>
                                        </div>
                                        <a target="_blank" href="https://cloud.pragicts.com"><h3>Cloud</h3></a>
                                        <p>Provisioning, management, and support of cloud platforms. Amazon, Microsoft, Google, and other third parties.</p>
                                        <p><a target="_blank" href="https://cloud.pragicts.com">More Details ...</a></p>
                                    </div>
                                </div>
                            </div>  */}
                            {/* <a href="portfolio.html" className="btn float-btn flat-btn color-btn">My Portfolio</a> */}
                        </div>
                    </div>
                </div>
            </div>
           {/*     <div className="container">
                <div className="row">
                    <div className="col-md-8">

                    <div className="col-md-4">  <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="2" data-height="45" data-nofollow="true" data-expandifr="true" data-scale="100" data-clutchcompany-id="1504388"></div></div>
                    <div className="col-md-4">    <a href="https://www.designrush.com/agency/profile/pragicts" target="_blank"><StaticImage src="../images/home/designrush-accredited-agency-pragicts.png" className="highlights" ></StaticImage></a></div>

                    </div>
                </div>
            </div>*/}
            <div className="bg-parallax-module" data-position-top="90"  data-position-left="25" data-scrollax="properties: { translateY: '-250px' }"></div>
            <div className="bg-parallax-module" data-position-top="70"  data-position-left="70" data-scrollax="properties: { translateY: '150px' }"></div>
            <div className="sec-lines"></div>
        </section>
    )
}

export default HomeAbout
